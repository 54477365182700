import react from 'react'
import Slider from 'react-slick'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Main() {

    return (
        <>
        <main id="main">
            {/* ======= Featured Services Section ======= */}
            <section id="featured-services" className="featured-services">
            <div className="container" data-aos="fade-up">
                <div className="row">
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={100}>
                    <div className="icon">
                        <i className="bx bxl-dribbble" />
                    </div>
                    <h4 className="title">
                        <a>Strategic Thinking</a>
                    </h4>
                    <p className="description">
                        Our firm offers a of strategic expertise to help achieve their
                        goals.{" "}
                    </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={200}>
                    <div className="icon">
                        <i className="bx bx-file" />
                    </div>
                    <h4 className="title">
                        <a>Creative Branding</a>
                    </h4>
                    <p className="description">
                        We develop a captivating and memorable brand identities, logos,
                        and visuals.
                    </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={300}>
                    <div className="icon">
                        <i className="bx bx-tachometer" />
                    </div>
                    <h4 className="title">
                        <a>Market Research</a>
                    </h4>
                    <p className="description">
                        Conducting in-depth research to understand consumer behavior,
                        market trends, and competition.
                    </p>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay={400}>
                    <div className="icon">
                        <i className="bx bx-world" />
                    </div>
                    <h4 className="title">
                        <a>Lead Generation</a>
                    </h4>
                    <p className="description">
                        We have an effective lead generation strategies to attract and
                        convert potential customers.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* End Featured Services Section */}
            {/* ======= About Section ======= */}
            <section id="about" className="about section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                <h2>About</h2>
                <h3>
                    Find Out More <span>About </span> <img id="img-who-main" style={{ width: 75, marginBottom: 15 }} src="assets/img/thebluhart-logo.png" alt="" />

                </h3>
                <p />
                </div>
                <div className="row">
                <div className="col-lg-6" data-aos="fade-right" style={{ }} data-aos-delay={100}>
                  <img
                    id="img-who"
                    src="assets/img/thebluhart-logo.png"
                    className="img-fluid"
                    alt=""
                    style={{ width: "80%", height: "80%", }}

                    />
                </div>
                <div
                    className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
                    data-aos="fade-up"
                    data-aos-delay={100}
                >
                    <h3>Who We Are?</h3>
                    <p className="" style={{ textAlign: "justify" }}>
                    BLU Hart is a dynamic and results-driven marketing firm founded in 2023. We specialize in tailored marketing solutions that elevate brand stories, engage audiences, and drive measurable results. As your trusted partner, we blend art and science to create holistic campaigns that resonate with uniqueness. With a commitment to understanding your vision, BLU Hart is here to guide your business towards success. Recognizing the distinctiveness of each enterprise, we work hand-in-hand, developing personalized strategies that go beyond needs to grasp visions and goals.
                    </p>
                    <p className="" style={{ textAlign: "justify" }}>
                    Our dynamic and results-driven approach has swiftly positioned us as a trusted ally for businesses seeking growth and prosperity. We are more than a marketing firm — we are your dedicated partner in success, offering a European-inspired journey into every campaign and strategy.
                    </p>
                </div>
                </div>
            </div>
            </section>
            {/* End About Section */}
            {/* ======= Counts Section ======= */}
            <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
                <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="count-box">
                    <i className="bi bi-emoji-smile" />
                    <span
                        data-purecounter-start={0}
                        data-purecounter-end={180}
                        data-purecounter-duration={1}
                        className="purecounter"
                    />
                    <p>Happy Clients</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                    <div className="count-box">
                    <i className="bi bi-journal-richtext" />
                    <span
                        data-purecounter-start={0}
                        data-purecounter-end={30}
                        data-purecounter-duration={1}
                        className="purecounter"
                    />
                    <p>Projects</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                    <div className="count-box">
                    <i className="bi bi-headset" />
                    <span
                        data-purecounter-start={0}
                        data-purecounter-end={1463}
                        data-purecounter-duration={1}
                        className="purecounter"
                    />
                    <p>Hours Of Support</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                    <div className="count-box">
                    <i className="bi bi-people" />
                    <span
                        data-purecounter-start={0}
                        data-purecounter-end={13}
                        data-purecounter-duration={1}
                        className="purecounter"
                    />
                    <p>Hard Workers</p>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* End Counts Section */}
            {/* ======= Clients Section ======= */}
            <section id="clients" className="clients">
            <div style={{ paddingTop: 50, paddingBottom: 50 }}>
              <Slider {...{ dots: true, infinite: true, speed: 500, slidesToShow: 4, slidesToScroll: 1, autoplay: true, autoplaySpeed: 1500, responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                            dots: true
                        }
                        },
                        {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            initialSlide: 2
                        }
                        },
                        {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                  ]
                }}>
                <div>
                  <img
                    src="assets/img/clients/tefer.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/clients/yenesew.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/clients/mena_kolo.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/clients/lomi-beauty.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/clients/nhy-logo.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src="assets/img/clients/london-cafe-ethiopia-logo.png"
                    className="testimonial-img"
                    alt=""
                  />
                </div>
              </Slider>
            </div>
            </section>

            {/* <section id="clients" className="clients section-bg">
            <div className="container" data-aos="zoom-in">
                <div className="row">
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/tefer.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/yenesew.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/mena_kolo.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/lomi-beauty.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/nhy-logo.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                    <img
                    src="assets/img/clients/london-cafe-ethiopia-logo.png"
                    className="img-fluid"
                    alt=""
                    />
                </div>
                </div>
            </div>
            </section> */}
            {/* End Clients Section */}
            {/* ======= Services Section ======= */}
            <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                <h2>Services</h2>
                <h3>
                    Check our <span>Services</span>
                </h3>
                <p>
                At BLU Hart, our services are crafted with precision and creativity, ensuring that your digital journey is not just impactful but also a reflection of your unique brand identity. We offer a suite of comprehensive services designed to elevate your digital presence and drive impactful results:
                </p>
                </div>
                <div className="row">
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-envelope" />
                    </div>
                    <h4>
                        <a>Email Marketing</a>
                    </h4>
                    <p>
                    Deliver highly effective email campaigns tailored to your audience, ensuring increased brand visibility, lead generation, and revenue growth. Our strategic approach transforms your communication into a powerful tool for engagement.
                    </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-slideshow" />
                    </div>
                    <h4>
                        <a>Social Media and Content Marketing</a>
                    </h4>
                    <p>
                    Stay ahead of your competitors by utilizing our data-driven social media marketing tactics. From content creation to audience targeting and performance analysis, we craft strategies that elevate your brand, foster community engagement, and drive measurable results.
                    </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-store-alt" />
                    </div>
                    <h4>
                        <a>Ad Creation and Promo</a>
                    </h4>
                    <p>
                    Our creative team crafts captivating ads tailored to your audience, delivering impactful messages that drive engagement and conversions. With a keen understanding of your brand, we create advertising campaigns that leave a lasting impression.
                    </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                    data-aos="zoom-in"
                    data-aos-delay={100}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-package" />
                    </div>
                    <h4>
                        <a>Branding and Identity Development</a>
                    </h4>
                    <p>
                    Fusing creativity and strategy, we go beyond logos. We develop compelling brand identities that reflect your values, positioning your business for success in the market. Our approach ensures that your brand stands out and resonates with your target audience.
                    </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                    data-aos="zoom-in"
                    data-aos-delay={200}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-globe" />
                    </div>
                    <h4>
                        <a>Website Design and Development</a>
                    </h4>
                    <p>
                    From concept to creation, our expert web designers and developers work closely with you to build custom websites that perfectly showcase your business objectives and captivate your target audience. Your online presence becomes a powerful tool for engagement and conversion.
                    </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
                    data-aos="zoom-in"
                    data-aos-delay={300}
                >
                    <div className="icon-box">
                    <div className="icon">
                        <i className="bx bx-search-alt" />
                    </div>
                    <h4>
                        <a>Search Engine Optimization</a>
                    </h4>
                    <p>
                    Stay ahead in the digital landscape with our advanced search engine optimization techniques. We enhance your website's visibility, ensuring it attracts quality leads. Our strategies are tailored to optimize your online presence and drive organic growth in the digital realm.
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* End Services Section */}
            {/* ======= Testimonials Section ======= */}
            <section id="testimonials" className="testimonials">
            <div className="container" data-aos="zoom-in">
                <div
                className="testimonials-slider swiper"
                data-aos="fade-up"
                data-aos-delay={100}
                >
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                    <div className="testimonial-item">
                        <img
                        src="assets/img/clients/lomi-beauty.png"
                        className="testimonial-img"
                        alt=""
                        />
                        <h3>Lomi Beauty</h3>
                        {/* <h4>Store Owner</h4> */}
                        <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        The Blu Hart Marketing firm's Social Media Content Creation
                        services have been an invaluable asset to our business. Their
                        team of talented creatives produced stunning visuals and
                        engaging copy that perfectly captured our brand's personality
                        and resonated with our target audience. We highly recommend
                        their services to anyone looking for exceptional social media
                        content.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                    </div>
                    </div>
                    {/* End testimonial item */}
                    <div className="swiper-slide">
                    <div className="testimonial-item">
                        <img
                        src="assets/img/clients/tefer.png"
                        className="testimonial-img"
                        alt=""
                        />
                        <h3>Tefer</h3>
                        {/* <h4>Ceo &amp; Founder</h4> */}
                        <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        The Blu Hart firm's Email Marketing services have been a
                        game-changer for our business. Their team helped us develop a
                        comprehensive email strategy and executed it flawlessly,
                        resulting in a significant increase in open rates,
                        click-through rates, and conversions. We highly recommend
                        their services to anyone looking to take their email marketing
                        to the next level.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                    </div>
                    </div>

                    <div className="swiper-slide">
                    <div className="testimonial-item">
                        <img
                        src="assets/img/clients/mena_kolo.png"
                        className="testimonial-img"
                        alt=""
                        />
                        <h3>Mena Kolo</h3>
                        {/* <h4>Designer</h4> */}
                        <p>
                        <i className="bx bxs-quote-alt-left quote-icon-left" />
                        Working with The Blu Hart has been an absolute pleasure. Their
                        Social Media Marketing services have been instrumental in
                        helping us grow our online presence and engage with our target
                        audience. Their team developed a creative and effective social
                        media strategy. We highly recommend their services to anyone
                        looking to elevate their social media game.
                        <i className="bx bxs-quote-alt-right quote-icon-right" />
                        </p>
                    </div>
                    </div>
                    {/* End testimonial item */}
                </div>
                <div className="swiper-pagination" />
                </div>
            </div>
            </section>
            {/* End Testimonials Section */}
            {/* ======= Portfolio Section ======= */}
            <section id="gallery" className="portfolio">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                <h2>Gallery</h2>
                <h3>
                    Check our <span>Portfolio</span>
                </h3>
                <p>
                    Immerse yourself in our portfolio that embodies our team's passion
                    for out-of-the-box thinking and creative excellence.
                </p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                    <li data-filter="*" className="filter-active">
                        All
                    </li>
                    <li data-filter=".filter-events">Events</li>
                    <li data-filter=".filter-printing">Printing</li>
                    <li data-filter=".filter-branding">Branding</li>
                    </ul>
                </div>
                </div>
                <div
                className="row portfolio-container"
                data-aos="fade-up"
                data-aos-delay={200}
                >
                <div className="col-lg-4 col-md-6 portfolio-item filter-branding">
                    <img
                    src="assets/img/portfolio/portfolio-1.jpeg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Event 1</p>
                    <a
                        href="assets/img/portfolio/portfolio-1.jpeg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Branding Service"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-printing">
                    <img
                    src="assets/img/portfolio/gallery-8.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Printing</h4>
                    <p>Printing 2</p>
                    <a
                        href="assets/img/portfolio/gallery-8.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Printing"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-branding">
                    <img
                    src="assets/img/portfolio/portfolio-3.jpeg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Branding</h4>
                    <p>Brand 1</p>
                    <a
                        href="assets/img/portfolio/portfolio-3.jpeg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Branding Service"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-7.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-7.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-2.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-2.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-3.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-3.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-4.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-4.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-1.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-1.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>


                <div className="col-lg-4 col-md-6 portfolio-item filter-events">
                    <img
                    src="assets/img/portfolio/gallery-6.jpg"
                    className="img-fluid"
                    alt=""
                    />
                    <div className="portfolio-info">
                    <h4>Events</h4>
                    <p>Shearton Addis New Years Eve Event</p>
                    <a
                        href="assets/img/portfolio/gallery-6.jpg"
                        data-gallery="portfolioGallery"
                        className="portfolio-lightbox preview-link"
                        title="Events organized"
                    >
                        <i className="bx bx-plus" />
                    </a>
                    <a href="#" className="details-link" title="More Details">
                        <i className="bx bx-link" />
                    </a>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* End Portfolio Section */}
            <section id="team">
            <div className="container">
                <div className="section-title">
                <h2>Team</h2>
                <h3>
                    Our Hardworking <span>Team</span>
                </h3>
                <br />
                <br />
                </div>
                <div className="row mt-n1-9">
                <div
                    className="col-md-6 col-xl-3 mt-1-9 wow fadeInUp"
                    data-wow-delay=".2s"
                    style={{
                    visibility: "visible",
                    animationDelay: "0.2s",
                    animationName: "fadeInUp"
                    }}
                >
                    <div className="team-style07">
                    <div className="team-thumb mb-1-9">
                        <div className="thumb">
                        <img
                            src="assets/img/tsion-tesfaye.png"
                            className="rounded-circle"
                            alt="..."
                        />
                        </div>
                        <div className="team-social">
                        <ul className="styled-icons">
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-facebook-f" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-twitter" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <h3 className="h5 mb-1">
                        <a id="team-name">Tsion Tesfaye</a>
                    </h3>
                    <p id="team-title" className="mb-0">Founder and Managing Director</p>
                    </div>
                </div>
                <div
                    className="col-md-6 col-xl-3 mt-1-9 wow fadeInUp"
                    data-wow-delay=".3s"
                    style={{
                    visibility: "visible",
                    animationDelay: "0.3s",
                    animationName: "fadeInUp"
                    }}
                >
                    <div className="team-style07">
                    <div className="team-thumb mb-1-9">
                        <div className="thumb">
                        <img
                            src="assets/img/christian-kassahun.png"
                            className="rounded-circle"
                            alt="..."
                        />
                        </div>
                        <div className="team-social">
                        <ul className="styled-icons">
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-facebook-f" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-twitter" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <h3 className="h5 mb-1">
                        <a>Christian Kassahun</a>
                    </h3>
                    <p className="mb-0">Co-Founder</p>
                    </div>
                </div>
                <div
                    className="col-md-6 col-xl-3 mt-1-9 wow fadeInUp"
                    data-wow-delay=".4s"
                    style={{
                    visibility: "visible",
                    animationDelay: "0.4s",
                    animationName: "fadeInUp"
                    }}
                >
                    <div className="team-style07">
                    <div className="team-thumb mb-1-9">
                        <div className="thumb">
                        <img
                            src="assets/img/yeabsira-tegene-thebluhart-image.jpg"
                            className="rounded-circle"
                            alt="..."
                        />
                        </div>
                        <div className="team-social">
                        <ul className="styled-icons">
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-facebook-f" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-twitter" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <h3 className="h5 mb-1">
                        {" "}
                        <a>Yeabsira Tegene</a>
                    </h3>
                    <p className="mb-0">Creative Director</p>
                    </div>
                </div>
                <div
                    className="col-md-6 col-xl-3 mt-1-9 wow fadeInUp"
                    data-wow-delay=".5s"
                    style={{
                    visibility: "visible",
                    animationDelay: "0.5s",
                    animationName: "fadeInUp"
                    }}
                >
                    <div className="team-style07">
                    <div className="team-thumb mb-1-9">
                        <div className="thumb">
                        <img
                            src="assets/img/sosina-terefe.png"
                            className="rounded-circle"
                            alt="..."
                        />
                        </div>
                        <div className="team-social">
                        <ul className="styled-icons">
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-facebook-f" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-twitter" />
                            </a>
                            </li>
                            <li>
                            <a className="styled-icons-item" href="#!">
                                <i className="fab fa-linkedin-in" />
                            </a>
                            </li>
                        </ul>
                        </div>
                    </div>
                    <h3 className="h5 mb-1">
                        {" "}
                        <a id="team-name">Sosina Terefe</a>
                    </h3>
                    <p id="team-title" className="mb-0">Marketing Officer</p>
                    </div>
                </div>
                </div>
            </div>
            </section>
            {/* End Team Section */}
            {/* ===== Latest Blogs Section ===== */}
            <section className="section gray-bg" id="blog">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-lg-7 text-center">
                    <div className="section-title">
                    <h2>Latest Blogs</h2>
                    <h3>
                        Check our <span>Blogs</span>
                    </h3>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-lg-4" id="blog-1">
                    <div className="blog-grid">
                    <div className="blog-img">
                        <div className="date">04 OCT</div>
                        <a href="#">
                        <img
                            src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*TrRqDoB10YhdRQ4t8ZmSbg@2x.jpeg"
                            title=""
                            alt=""
                            style={{ height: 280 }}

                        />
                        </a>
                    </div>
                    <div className="blog-info">
                        <h5>
                        <a href="#">
                           Email Marketing Uncovered: Your Ultimate Guide to Success
                        </a>
                        </h5>
                        <p>
                        Discover the key elements of successful email marketing,
                        from captivating subject lines to designing visually appealing
                        contents
                        </p>
                        <div className="btn-bar">
                        <a href="blog.thebluhart.com" className="px-btn-arrow">
                            <span>Read More</span>
                            <i className="arrow" />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4" id="blog-2">
                    <div className="blog-grid">
                    <div className="blog-img">
                        <div className="date">07 OCT</div>
                        <a href="https://blog.thebluhart.com">
                        <img
                            src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*Bd8e-Jm3rzO0gAR-.jpg"
                            title=""
                            alt=""
                            style={{ height: 280 }}
                        />
                        </a>
                    </div>
                    <div className="blog-info">
                        <h5>
                        <a href="https://blog.thebluhart.com">
                            Building a Memorable Brand from Scratch: Proven Tips and Strategies for Startups
                        </a>
                        </h5>
                        <p>
                        Discover the key elements of how to build a momorable brand from scratch.
                        </p>
                        <div className="btn-bar">
                        <a href="https://blog.thebluhart.com" className="px-btn-arrow">
                            <span>Read More</span>
                            <i className="arrow" />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4" id="blog-3">
                    <div className="blog-grid">
                    <div className="blog-img">
                        <div className="date">16 OCT</div>
                        <a href="#">
                        <img
                            src="https://images.unsplash.com/photo-1501504905252-473c47e087f8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8b25saW5lJTIwdmlzaWJpbGl0eXxlbnwwfHwwfHx8MA%3D%3D"
                            title=""
                            alt=""
                            style={{ height: 280 }}
                        />
                        </a>
                    </div>
                    <div className="blog-info">
                        <h5>
                        <a href="https://blog.thebluhart.com">
                            Mastering Online Visibility: Essential First Steps and 6 Useful Google Tools for Your Business
                        </a>
                        </h5>
                        <p>
                        How to master online visiblity? Disover the essential steps and useful tools for your business!
                        </p>
                        <div className="btn-bar">
                        <a href="https://blog.thebluhart.com" className="px-btn-arrow">
                            <span>Read More</span>
                            <i className="arrow" />
                        </a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <footer id="footer">
            <div className="footer-newsletter">
                <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                    <h4>Join Our Newsletter</h4>
                    <p>
                        Subscribe to our newsletter and stay up-to-date with the latest
                        news, exclusive offers, and industry insights!
                    </p>
                    <form action="" method="post">
                        <input type="email" name="email" />
                        <input type="submit" defaultValue="Subscribe" />
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </footer>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                <h2>Contact</h2>
                <h3>
                    <span>Contact Us</span>
                </h3>
                <p>Get in touch with us to take your company to the next level</p>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-6">
                    <div className="info-box mb-4">
                    <i className="bx bx-map" />
                    <h3>Our Address</h3>
                    <p>Meti Building, Namibia Avenue, Addis Ababa, Ethiopia</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                    <i className="bx bx-envelope" />
                    <h3>Email Us</h3>
                    <p>info@thebluhart.com</p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <div className="info-box  mb-4">
                    <i className="bx bx-phone-call" />
                    <h3>Call Us</h3>
                    <a href="tel:+251991404568">
                        <p>+251 991 40 45 68</p>
                    </a>
                    </div>
                </div>
                </div>
                <div className="row" data-aos="fade-up" data-aos-delay={100}>
                <div className="col-lg-6 ">
                    <iframe
                    className="mb-4 mb-lg-0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1970.4396287032096!2d38.80881949232374!3d8.983256853791739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85f03c06f10b%3A0x76120a453725919c!2sBlu%20Marketing!5e0!3m2!1sen!2set!4v1697704060424!5m2!1sen!2set"
                    frameBorder={0}
                    style={{ border: 0, width: "100%", height: 384 }}
                    allowFullScreen=""
                    />
                </div>
                <div className="col-lg-6">
                    <form
                    action="#"
                    method="post"
                    role="form"
                    className="php-email-form"
                    >
                    <div className="row">
                        <div className="col form-group">
                        <input
                            type="text"
                            name="name"
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                            required=""
                        />
                        </div>
                        <div className="col form-group">
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Your Email"
                            required=""
                        />
                        </div>
                    </div>
                    <div className='row'>
                      <div className="col form-group">
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="name"
                            placeholder="Your Phone"
                            required=""
                        />
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required=""
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                        className="form-control"
                        name="message"
                        rows={5}
                        placeholder="Message"
                        required=""
                        defaultValue={""}
                        />
                    </div>
                    <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message" />
                        <div className="sent-message">
                        Your message has been sent. Thank you!
                        </div>
                    </div>
                    <div className="text-center">
                        <button type="submit">Send Message</button>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </section>
            {/* End Contact Section */}
        </main>
        {/* End #main */}
        </>

    )
}