import react from 'react';

export default function Topbar() {
    return (
    <>
      {/* ======= Top Bar ======= */}
      <section id="topbar" className="d-flex align-items-center">
        <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
            <i className="bi bi-envelope d-flex align-items-center">
                <a href="mailto:contact@example.com">info@thebluhart.com</a>
            </i>
            <i className="bi bi-phone d-flex align-items-center ms-4">
                <a href="tel:+251991404568">
                <span>+251 991 40 45 68​</span>
                </a>
            </i>
            </div>
            <div className="social-links d-none d-md-flex align-items-center">
            <a href="#" className="twitter">
                <i className="bi bi-twitter" />
            </a>
            <a href="#" className="facebook">
                <i className="bi bi-facebook" />
            </a>
            <a href="https://instagram.com/blu_hart_marketing" target="_blank" className="instagram">
                <i className="bi bi-instagram" />
            </a>
            <a href="#" className="linkedin">
                <i className="bi bi-tiktok" />
            </a>
            <a href="#" className="linkedin">
                <i className="bi bi-linkedin" />
            </a>
            </div>
        </div>
      </section>
    </>
    )
}