import React from 'react';

import Footer from './Footer';
import Header from './Header';
import Main from './Main';
import Topbar from './Topbar';

import './App.css';


function App() {
  React.useEffect(() => {


    const LoadExternalScript = () => {
        const externalScript = document.createElement("script");
        externalScript.onerror = '';
        externalScript.id = "external";
        externalScript.async = true;
        externalScript.type = "text/javascript";
        externalScript.setAttribute("crossorigin", "anonymous");
        document.body.appendChild(externalScript);
        externalScript.src = 'assets/js/main.js';
  };
  LoadExternalScript();

  })

  return (
    <div>
      <Topbar />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;