import react from 'react';

export default function Footer() {
    return (
        <>
        {/* ======= Footer ======= */}
        <footer id="footer">
            <div className="footer-top">
            <div className="container">
                <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                    {/* <h3><span>TheBluHart</span></h3> */}
                    <a href="#" className="logo">
                    <img
                        id="footer-logo"
                        src="assets/img/thebluhart-logo.png"
                        alt=""
                    />
                    </a>
                    <p>
                    Meti Building, Namibia Avenue <br />
                    Addis Ababa,
                    <br />
                    Ethiopia <br />
                    <br />
                    <strong>Phone:</strong> +251 991 40 45 68​
                    <br />
                    <strong>Email:</strong> info@thebluhart.com
                    <br />
                    </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Useful Links</h4>
                    <ul>
                    <li>
                        <i className="bx bx-chevron-right" /> <a href="#hero">Home</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#about">About us</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#services">Services</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#gallery">Gallery</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" /> <a href="#team">Team</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#contact">Contact</a>
                    </li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Our Services</h4>
                    <ul>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#">Email Marketing</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#">Social Media and Content Marketing</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#">Website Development</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#">Search Enginge Optimization</a>
                    </li>
                    <li>
                        <i className="bx bx-chevron-right" />{" "}
                        <a href="#">Brand and Identity Development</a>
                    </li>
                    </ul>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Our Social Networks</h4>
                    <p>Get in touch with us on social media</p>
                    <div className="social-links mt-3">
                    <a href="#" className="twitter">
                        <i className="bx bxl-twitter" />
                    </a>
                    <a href="#" className="facebook">
                        <i className="bx bxl-facebook" />
                    </a>
                    <a href="https://instagram.com/blu_hart_marketing" target='_blank' className="instagram">
                        <i className="bx bxl-instagram" />
                    </a>
                    <a href="#" className="google-plus">
                        <i className="bx bxl-tiktok" />
                    </a>
                    <a href="#" className="linkedin">
                        <i className="bx bxl-linkedin" />
                    </a>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className="container py-4">
            <div className="copyright">
                © Copyright 2023{" "}
                <strong>
                <span>TheBluHart</span>
                </strong>
                . All Rights Reserved
            </div>
            <div className="credits">
                Designed by <a href="https://thebluhart.com/">TheBluHart</a>
            </div>
            </div>
        </footer>
        {/* End Footer */}
        </>
    )
}