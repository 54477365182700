import React from 'react'
import Typed from 'typed.js'

export default function Header() {

    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);

    React.useEffect(() => {
        const typed = new Typed(el.current, {
          strings: [
                "business growth",
                "online presence",
                "return on investment",
                "social media engagement",
                "website traffic"
            ],
            typeSpeed: 80,
            loop: true,
        });
        return () => {
          // Destroy Typed instance during cleanup to stop animation
          typed.destroy();
        };
    }, []);

    return (
        <>
        <header id="header" className="d-flex align-items-center">
            <div className="container d-flex align-items-center justify-content-between">
            {/* <h1 class="logo"><a href="/">The Blu Hart<span>.</span></a></h1> */}
            <a href="/" className="logo">
                <img src="assets/img/thebluhart-logo.png" alt="" />
            </a>
            <nav id="navbar" className="navbar">
                <ul>
                <li>
                    <a className="nav-link scrollto active" href="#hero">
                    Home
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="#about">
                    About
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="#services">
                    Services
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto " href="#gallery">
                    Gallery
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="#blog">
                    Blogs
                    </a>
                </li>
                <li>
                    <a className="nav-link scrollto" href="#contact">
                    Contact
                    </a>
                </li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle" />
            </nav>
            </div>
        </header>
        {/* ======= Header Section ======= */}
        <section id="hero" className="d-flex align-items-center">
            <div className="container" data-aos="zoom-out" data-aos-delay={100}>
            <h1>
                Welcome to <span>TheBluHart</span>
            </h1>
            <h2 className="typing-text">
                <span id="text-static">Boost your </span>
                <span ref={el} id="text" />
            </h2>
            <div className="d-flex">
                <a href="#about" className="btn-get-started scrollto">
                Let's Meet
                </a>
                <a
                href="assets/docs/thebluhart-portfolio.pdf"
                className="glightbox btn-watch-video"
                >
                <i className="bi bi-book" />
                <span>Read Portfolio</span>
                </a>
            </div>
            </div>
        </section>
        {/* End Hero */}
        </>
    )
}

